import { getFirestore, where } from 'firebase/firestore';
import { collection, query, getDocs, getDoc, doc } from 'firebase/firestore';
import firebaseApp from '../configs/configs';

const CategoriesData = {
  async getAllCategories() {
    let db = getFirestore(firebaseApp);
    let q = query(collection(db, 'categories'));
    let data = await getDocs(q);
    let _rows = [];
    for (let i = 0; i < data.size; i++) {
      _rows.push({
        id: data.docs[i].id,
        name: data.docs[i].data()['name'],
      });
    }
    return Promise.resolve(_rows);
  },

  async getSubCategories(categoryId) {
    let db = getFirestore(firebaseApp);
    let q = query(collection(db, 'subCategory'), where('categoryId', '==', categoryId));
    let data = await getDocs(q);
    let _rows = [];
    for (let i = 0; i < data.size; i++) {
      _rows.push({
        id: data.docs[i].id,
        name: data.docs[i].data()['name'],
        image: data.docs[i].data()['image'],
      });
    }
    return Promise.resolve(_rows);
  },

  async getCategorybyId(categoryId) {
    let db = getFirestore(firebaseApp);
    const ref = doc(db, 'categories', categoryId);
    try {
      const data = await getDoc(ref);
      return data.data();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getSubCategorybyId(categoryId) {
    let db = getFirestore(firebaseApp);
    const ref = doc(db, 'subCategory', categoryId);
    try {
      const data = await getDoc(ref);
      return data.data();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
export default CategoriesData;
