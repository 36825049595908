import * as React from 'react';
import styles from './login.module.css';
import appLogo from '../../assets/images/logo.png';
import loginBg from '../../assets/images/login-bg.png';
import localization from '../../l18n/l18n';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import authUser from '../../models/auth';
import MuiAlert from '@mui/material/Alert';
import { UserContext } from '../../index';
import { useLocation, useNavigate } from 'react-router-dom';
import UserData from '../../models/users.tsx';
import { getFcmToken } from '../../models/notification';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  /* Initializing the state of the component. */
  const initialFormData = Object.freeze({
    email: '',
    password: '',
  });

  const initailValidationData = Object.freeze({
    email: '',
    password: '',
  });

  const [formData, updateFormData] = React.useState(initialFormData);
  const [validationData, updateValidationData] = React.useState(initailValidationData);
  const [isLoading, updateIsLoading] = React.useState(false);
  const [open, updateOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const { user } = React.useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (user) {
      if (location.state?.from) {
        return navigate(location.state.from.pathname);
      }
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /**
   * It takes the current form data, and updates it with the new value of the input that was changed
   */
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleRemember = (e) => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData(formData)) {
      return;
    }
    updateIsLoading(true);
    // ... authonticate user
    try {
      const response = await authUser.signIn(formData.email, formData.password, isChecked);
      if (response) {
        const fcmToken = await getFcmToken();
        if (fcmToken) {
          await UserData.updateToken(response.user.uid, fcmToken);
          if (location.state?.from) {
            navigate(location.state.from.pathname);
          }
        }
        updateIsLoading(false);
        navigate('/');
      }
    } catch (e) {
      console.log(e);
      updateIsLoading(false);
      updateOpen(true);
    }
  };

  /**
   * It checks if the email is valid and if the password is at least 6 characters long
   */
  const validateFormData = (formData) => {
    // error show when use reg.test:
    // Uncaught TypeError: reg.test is not a function at validateFormData

    // const reg = '^w+([.-]?w+)*@w+([.-]?w+)*(.w{1,4})+$';
    const errors = {};
    // if (!formData.email || reg.test(formData.email) === false) {
    //   errors.email = localization.translate.email_required;
    // } else if (!formData.password || formData.password.length < 6) {
    //   errors.password = localization.translate.password_required;
    // }
    if (errors.email || errors.password) {
      updateValidationData({
        ...validationData,
        email: errors.email,
        password: errors.password,
      });
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        severity="error">
        <Alert style={{ fontSize: '1.2rem', fontFamily: 'var(--ff-cairo)' }} severity="error">
          {localization.translate.no_match}
        </Alert>
      </Snackbar>
      <div className={styles.container}>
        <div className={styles.app_logo}>
          <img src={appLogo} alt="Nahla brand logo" />
        </div>
        <div className={styles.login_card}>
          <div className={styles.card_form}>
            <h2>{localization.translate.login_welcome}</h2>
            <form id="login-form" className={styles.login_form} onSubmit={handleSubmit}>
              <h3>{localization.translate.login}</h3>
              <div className={styles.input_wrapper}>
                <label htmlFor="email">{localization.translate.email} </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  data-testid="email"
                  value={formData.email}
                  placeholder={localization.translate.email_placeholder}
                  onChange={handleChange}
                  required
                />
                {validationData.email && (
                  <span data-testid="email-msg" className={styles.input_error}>
                    {validationData.email}
                  </span>
                )}
              </div>
              <div className={styles.input_wrapper}>
                <label htmlFor="password">{localization.translate.password}</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  data-testid="password"
                  value={formData.password}
                  placeholder={localization.translate.password_placeholder}
                  onChange={handleChange}
                  required
                />
                {validationData.password && (
                  <span data-testid="password-msg" className={styles.input_error}>
                    {validationData.password}
                  </span>
                )}
              </div>
              <div className={styles.input_wrapper} data="input_remember">
                <label htmlFor="remeber_me"> {localization.translate.remeber_me} </label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  name="remeber_me"
                  onChange={handleRemember}
                />
              </div>
              <div className={styles.input_wrapper}>
                <LoadingButton type="submit" loading={isLoading}>
                  {localization.translate.login}
                </LoadingButton>
              </div>
            </form>
          </div>
          <div className={styles.login_card_media}>
            <img src={loginBg} alt="Nahla login background" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
