import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';
import localization from '../../l18n/l18n';
import styled from 'styled-components';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Link } from 'react-router-dom';
import moneyIcon from '../../assets/images/fluent_money-16-regular.svg';
import OrderData from '../../models/orders.tsx';

const Img = styled.img`
  max-width: 24px;
`;

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

const OrderCard = (props) => {
  const { order } = props;
  const [orderDate, timeValues] = order.dateTime.split(' ');
  const [hours, minutes] = timeValues.split(':');
  const orderTime = tConvert(`${hours}:${minutes}`);
  const [customer, setCustomer] = React.useState(null);

  const handleOrderStatusChange = (e) => {
    OrderData.updateOrderStatus(order.id, '3')
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log('error_change_status', error);
      });
  };
  const getState = () => {
    const initialOrderStatus = localization.translate.order_status.find((status) => status.value === order.status);
    if (initialOrderStatus){
      return initialOrderStatus.label
    }
    return "";
  }
  React.useEffect(() => {
    console.log(order)
    OrderData.getCustomerById(order.customerId).then((customer) => {
      setCustomer(customer);
    });
  }, [order]);
  return (
    <Card sx={{ maxWidth: 354, boxShadow: 2, backgroundColor: order.status === '3' ? '#B6EAB2' : '#F6F6FB' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography variant='h6' sx={{ fontWeight: '700', fontSize: '1rem' }}>
            {customer && customer.firstName} {customer && customer.lastName}
          </Typography>
          <Typography variant='p' sx={{ fontWeight: '400', fontSize: '0.875rem' }}>
            {orderDate}
          </Typography>
          <Typography variant='p' sx={{ fontWeight: '400', fontSize: '0.875rem', direction: 'initial' }}>
            {orderTime}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.2rem', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <LocationOnOutlinedIcon sx={{ color: 'var(--clr-primary)' }} />
          <Typography variant='p' sx={{ fontWeight: '400' }}>
            {order.address}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.2rem', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', gap: '0.4rem', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Img src={moneyIcon} alt='money' />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.2rem',
                justifyContent: 'flex-start',
                flexDirection: 'row-reverse',
                fontSize: '1.125rem',
              }}>
              <Typography gutterBottom variant='p'>
                {order.totalAmount.toLocaleString('en-US')}
              </Typography>
              <Typography gutterBottom variant='p'>
                {props.currency}
              </Typography>
            </Box>
          </Box>
          <Button
            component={Link}
            to={`/orders/${order.id}`}
            variant='contained'
            sx={{ px: '0.9rem', py: '0.2rem', borderRadius: '5rem', fontWeight: '700', fontSize: '0.616rem' }}>
            {localization.translate.show_more}
          </Button>
        </Box>
        {props.isDelivered && (
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Typography variant='p' sx={{ fontWeight: '400' }}>
              {localization.translate.order_status_title}
            </Typography>
            <Button
              sx={{
                backgroundColor: 'var(--clr-green)',
                px: '0 !important',
                py: '0 !important',
                minWidth: '0',
                color: 'var(--clr-white)',
              }}
              onClick={handleOrderStatusChange}
              variant='contained'
              endIcon={<CheckOutlinedIcon sx={{ mx: '0 !important' }} />}
            />
          </Box>
        )}
        <Divider />
        <Box>
            {getState()} 
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrderCard;
