import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import Box from '@mui/material/Box';
import {
  Button,
  Grid,
  Input,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import localization from '../../l18n/l18n';
import ProductCard from '../components/ProductCard';
import { Link } from 'react-router-dom';
import ProductData from '../../models/products.tsx';
import Loading from '../components/loading';
import CategoriesData from '../../models/categories';
import { useSearchParams } from 'react-router-dom';
import productModels from './models/productModels.tsx';

const Products = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');
  const [searchBy, setSearchBy] = useState('all');
  const [page, setPage] = useState(1);
  
  const [productList, setProductList] = useState([]);
  const [mainCategory, setMainCategory] = React.useState('');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [subOpen, setSubOpen] = React.useState(false);
  const [mainCategoryValue, setMainCategoryValue] = useState('');
  const [subCategoryValue, setSubCategoryValue] = useState('');
  const loading = open && categories.length === 0;
  const subLoading = subOpen && subCategories.length === 0;
  const [alertMsg, setAlertMsg] = useState(<></>);

  const getAll = () => {
    setProductList([]);
    setAlertMsg(<Loading />);
    let currentParam = Object.fromEntries([...searchParams]);
    if (currentParam.page === undefined) {
      currentParam.page = 1;
      currentParam.searchValue = "";
      currentParam.searchBy = "";
    }
    productModels.fetchProducts(currentParam.page, currentParam.searchBy, currentParam.searchValue)
    .then((data) => {
      setProductList(data);
      setAlertMsg(<></>);
    })
    .catch((error) => {
      console.log(error);
      setAlertMsg(<>{error.message}</>);
    });
  };
  const goNext = () => {
    setPage(page + 1);
    setSearchParams(
      new URLSearchParams({ page: page + 1, searchValue: searchValue, searchBy: searchBy })
    );
  };
  const goPrevious = () => {
    if (page >= 2) {
      setPage(page - 1);
      setSearchParams(
        new URLSearchParams({ page: page - 1, searchValue: searchValue, searchBy: searchBy })
      );
    }
  };

  const handleMainCategory = async () => {
    setOpen(true);
  };

  const handleSubCategory = async () => {
    setSubOpen(true);
    setSubCategories([]);
    try {
      const categories = await CategoriesData.getSubCategories(mainCategory.id);
      setSubCategories(categories);
      if (categories.length === 0) {
        setSubOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMainCategoryProducts = async (e, value, reason) => {
    if (reason === 'clear') {
      getAll();
      setSearchParams({});
      handleSubCategoryProducts(null, null, reason === 'clear');
      setMainCategory('');
      return;
    }
    if (value) {
      setMainCategory(value);
      setSubCategoryValue('');
      setMainCategoryValue(value?.name);
      setPage(1);
      setSearchParams(
        new URLSearchParams({ page:1, searchValue: value?.id, searchBy:'category' })
      );
    }
  };

  const handleSubCategoryProducts = async (e, value, reason) => {
    if (reason === 'clear') {
      handleMainCategoryProducts(null, mainCategory, (reason = 'none'));
      setSearchParams({});
      return;
    }
    if (value) {
      setSubCategoryValue(value?.name);
      try {
        const products = await ProductData.getProductBySubCategory(value?.id);
        setProductList(products);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleMainCategoryInputChange = async (e, value, reason) => {
    if (reason === 'input') {
      setMainCategoryValue(value);
    }
  };
  const handleSubCategoryInputChange = async (e, value, reason) => {
    if (reason === 'input') {
      setSubCategoryValue(value);
    }
  };

  const getCategories = async () => {
    try {
      const categories = await CategoriesData.getAllCategories();
      setCategories(categories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
    // getQueryParams();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let currentParam = Object.fromEntries([...searchParams]);
    if (currentParam.page !== undefined) {
      setPage(Number(currentParam.page));
      setSearchValue(currentParam.searchValue);
      setSearchBy(currentParam.searchBy);
    }
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Container
      maxWidth="false"
      sx={{
        py: '2rem',
        px: '4rem !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2rem',
      }}>
      <Box
        sx={{
          flexWrap: 'wrap',
          display: 'flex',
          gap: '2.625rem',
          width: '100%',
          alignItems: { xs: 'flex-start', sm: 'center' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          flexDirection: { xs: 'column', sm: 'row' },
        }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.375rem' }}>
          {localization.translate.my_products_title}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            flexWrap: 'wrap',
            gap: '1rem',
            display: 'flex',
            alignItems: { xs: 'flex-start', sm: 'center' },
            justifyContent: { xs: 'center', sm: 'space-between' },
            flexDirection: { xs: 'column', sm: 'row' },
          }}>
          <Input
            sx={{
              flexGrow: 1,
              backgroundColor: 'var(--clr-light-gray)',
              borderRadius: '5rem',
              py: '0.5rem',
              px: '2.375rem',
            }}
            placeholder={localization.translate.product_search_placeholder}
            disableUnderline={true}
            variant="standard"
            onKeyUp={(event) => {
              setSearchValue(event.target.value);
              if (event.keyCode === 13){
                setPage(1);
                setSearchParams(
                  new URLSearchParams({ page:1, searchValue: event.target.value, searchBy:'name' })
                );
              }
            }}
          />
          <Button
            variant="contained"
            sx={{
              px: '3.375rem',
              py: '0.5rem',
              borderRadius: '5rem',
              fontWeight: '700',
            }}
            onClick={()=>{
              setPage(1);
              setSearchParams(
                new URLSearchParams({ page:1, searchValue: searchValue, searchBy:'name' })
              );
            }}>
            {localization.translate.search}
          </Button>
        </Box>
        <Button
          variant="outlined"
          component={Link}
          to="/products/new"
          sx={{
            px: '3.375rem',
            py: '0.5rem',
            borderRadius: '5rem',
            borderColor: 'var(--clr-light-blue)',
            fontWeight: '700',
            color: 'var(--clr-light-blue)',
          }}>
          {localization.translate.add_product_button}
        </Button>
      </Box>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          display: 'inline-flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignSelf: 'flex-start',
        }}>
        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <Autocomplete
            sx={{ width: 300 }}
            open={open}
            onOpen={handleMainCategory}
            onClose={() => {
              setOpen(false);
            }}
            onChange={handleMainCategoryProducts}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            inputValue={mainCategoryValue}
            onInputChange={handleMainCategoryInputChange}
            options={categories}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                id="mainCategory"
                label={localization.translate.main_category}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        {mainCategory && (
          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <Autocomplete
              sx={{ width: 300 }}
              open={subOpen}
              onOpen={handleSubCategory}
              onClose={() => {
                setSubOpen(false);
              }}
              onChange={handleSubCategoryProducts}
              isOptionEqualToValue={(option, value) => option?.name === value.name}
              getOptionLabel={(option) => option?.name}
              options={subCategories}
              loading={subLoading}
              inputValue={subCategoryValue}
              onInputChange={handleSubCategoryInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="subCategory"
                  label={localization.translate.sub_category}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {subLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        )}
      </Stack>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1,1fr)',
            md: 'repeat(3,1fr)',
            lg: 'repeat(5,1fr)',
          },
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '2rem',
          width: '100%',
        }}
        container
        columns={{ xs: 1, sm: 3, md: 5 }}>
        {productList.map((productItem, index) => {
          return (
            <Grid item xl={5} key={index}>
              <ProductCard
                key={index}
                product={productItem}
                image={productItem.thumbnail_image_url}
              />
            </Grid>
          );
        })}
      </Grid>
      {alertMsg}
      <Stack direction="row" sx={{ minWidth: '50%', justifyContent: 'space-between' }}>
        <Button
          onClick={goPrevious}
          startIcon={<KeyboardArrowRightIcon />}>
          {localization.translate.go_previous}
        </Button>
        <Button
          onClick={goNext}
          endIcon={<KeyboardArrowLeftIcon />}>
          {localization.translate.go_next}
        </Button>
      </Stack>
      
    </Container>
  );
};

export default Products;
