// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const Env = 'production';//'production';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBz9SmRXlVbBE9pm3JulCVGoK_qwnEZYwE',
  authDomain: 'deliverytest-1474e.firebaseapp.com',
  databaseURL: 'https://deliverytest-1474e-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'deliverytest-1474e',
  storageBucket: 'deliverytest-1474e.appspot.com',
  messagingSenderId: '142536516068',
  appId: '1:142536516068:web:fad8d45b4025dbc65227f1',
  measurementId: 'G-41X0Z019XW',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// API 
const APIEndpoints = (Env === 'development') ? "http://localhost/" : "https://api.nahla.app/";


export {APIEndpoints};
export default firebaseApp;
