import { getFirestore, where } from 'firebase/firestore';
import { collection, query, getDocs } from 'firebase/firestore';
import firebaseApp from '../configs/configs';

const BrandssData = {
  async getAllBrands() {
    let db = getFirestore(firebaseApp);
    let q = query(collection(db, 'brand'));
    let data = await getDocs(q);
    let _rows = [];
    for (let i = 0; i < data.size; i++) {
      _rows.push({
        id: data.docs[i].id,
        name: data.docs[i].data()['name'],
        imagePath: data.docs[i].data()['imagePath'],
      });
    }
    return Promise.resolve(_rows);
  },

  async getBrand(brandId) {
    let db = getFirestore(firebaseApp);
    let q = query(collection(db, 'brand'), where('id', '==', brandId));
    let data = await getDocs(q);
    let _rows = [];
    for (let i = 0; i < data.size; i++) {
      _rows.push({
        id: data.docs[i].id,
        name: data.docs[i].data()['name'],
        image: data.docs[i].data()['image'],
      });
    }
    return Promise.resolve(_rows);
  },
};
export default BrandssData;
