import React from 'react';
import { Container, Box } from '@mui/system';
import {
  Stack,
  InputLabel,
  Input,
  Typography,
  Select,
  MenuItem,
  TextareaAutosize,
  Divider,
  styled,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import localization from '../../l18n/l18n';
import uploadIcon from '../../assets/images/upload-icon.svg';
import CategoriesData from '../../models/categories';
import BrandsData from '../../models/brands';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Image } from 'mui-image';
import productModels from './models/productModels.tsx';

const inputStyles = {
  backgroundColor: 'var(--clr-light-gray)',
  borderRadius: '5rem',
  py: '0.5rem',
  px: '2.375rem',
  fontSize: '1rem',
  color: 'var(--clr-black)',
  fontweight: 400,
  '.MuiOutlinedInput-notchedOutline': {
    border: 'inherit',
  },
  '.MuiOutlinedInput-root': {
    position: 'initial !important',
    padding: 0,
  },
  svg: {
    mr: '1rem',
  },
  '&.Mui-error': {
    border: '1px solid red !important',
    color: 'red',
  },
};

const inputLabelStyles = {
  fontSize: '1rem',
  color: 'var(--clr-black)',
  fontweight: 400,
  span: {
    color: 'red',
  },
};

const textAreaStyles = {
  backgroundColor: 'var(--clr-light-gray)',
  borderRadius: '1rem',
  fontSize: '1rem',
  color: 'var(--clr-black)',
  fontweight: 400,
  padding: '1rem',
};

const Img = styled('img')({
  width: 75,
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddProduct = () => {
  const intialProductData = {
    name: '',
    imagePath: '',
    price: '',
    category_id: '',
    sub_category_id: '',
    description: '',
    brand_iD: '',
    merchant_id: 0,
    barcode: '',
    brand_id: '',
  };

  const [mainCategory, updateMainCategory] = React.useState('');
  const [categoriesData, setCategoriesData] = React.useState([]);
  const [subCategoriesData, setSubCategoriesData] = React.useState([]);
  const [subCategory, updateSubCategory] = React.useState('');
  const [file, setFile] = React.useState();
  const [productData, setProductData] = React.useState(intialProductData);
  const [brands, setBrands] = React.useState([]);
  const [alertMsg, setAlertMsg] = React.useState(<></>);

  const navigate = useNavigate();

  const handleInputChagnge = (event) => {
    setProductData({ ...productData, [event.target.name]: event.target.value });
  };

  const handelMainCategory = (event) => {
    updateMainCategory(event.target.value);
    setProductData({ ...productData, [event.target.name]: event.target.value });
  };

  const handelSubCategory = (event) => {
    updateSubCategory(event.target.value);
    setProductData({ ...productData, [event.target.name]: event.target.value });
  };

  const handelSubmit = async (event) => {
    event.preventDefault();
    const fd = new FormData();
    fd.append("name", productData.name);
    fd.append("price", productData.price);
    fd.append("merchant_id", productData.merchant_id ?? 0);
    fd.append("discount", productData.discount ?? 0);
    fd.append("category_id", productData.category_id);
    fd.append("sub_category_id", productData.sub_category_id);
    fd.append("description", productData.description);
    fd.append("barcode", '123');
    fd.append("image", file);
    setAlertMsg(
      <Snackbar
        open
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        severity="error"
        onClose={() => { setAlertMsg(<></>) }}>
        <CircularProgress />
      </Snackbar>
    );
    productModels.createProduct(fd)
      .then((data) => {
        setAlertMsg(<></>);
        navigate('/products');
      })
      .catch((error) => {
        setAlertMsg(
          <Snackbar
            open
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            severity="error"
            onClose={() => { setAlertMsg(<></>) }}>
            <Alert style={{ fontSize: '1.2rem', fontFamily: 'var(--ff-cairo)' }} severity="error">
              {error.message}
            </Alert>
          </Snackbar>
        );
      });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const fetchData = async () => {
    CategoriesData.getAllCategories().then((categories) => {
      BrandsData.getAllBrands().then((brands) => {
        setCategoriesData(categories);
        setBrands(brands);
      });
    });
  };
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    const fetchData = async () => {
      const categories = await CategoriesData.getSubCategories(mainCategory);
      setSubCategoriesData(categories);
    };
    fetchData();
    // eslint-disable-next-line
  }, [mainCategory]);

  return (
    <React.Fragment>
      <Container
        maxWidth="false"
        sx={{
          py: '2rem',
          px: '4rem !important',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-center',
          gap: '4rem',
        }}>
        <Typography
          gutterBottom
          variant="h2"
          sx={{ fontSize: '1.375rem', fontWeight: '700', alignSelf: 'flex-start' }}>
          {localization.translate.add_product}
        </Typography>
        <Form onSubmit={handelSubmit}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
              gap: '3rem',
            }}>
            <Stack spacing={4} width="100%">
              <Stack spacing={2}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.product_name}
                </InputLabel>
                <Input
                  onChange={handleInputChagnge}
                  name="name"
                  value={productData.name}
                  sx={inputStyles}
                  disableUnderline={true}
                  placeholder={localization.translate.product_name}
                  required
                />
              </Stack>
              <Stack spacing={2}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.barcode}
                </InputLabel>
                <Input
                  type="text"
                  onChange={handleInputChagnge}
                  name="barcode"
                  value={productData.barcode}
                  sx={inputStyles}
                  disableUnderline={true}
                  placeholder={localization.translate.barcode}
                  required
                />
              </Stack>
              <Stack spacing={2}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.product_price}
                </InputLabel>
                <Input
                  type="number"
                  onChange={handleInputChagnge}
                  name="price"
                  value={productData.price}
                  sx={inputStyles}
                  disableUnderline={true}
                  placeholder={localization.translate.product_price}
                  required
                />
              </Stack>
              <Stack spacing={2}>
                <InputLabel sx={inputLabelStyles}>
                  {localization.translate.discount}
                </InputLabel>
                <Input
                  type="number"
                  onChange={handleInputChagnge}
                  name="discount"
                  value={productData.discount}
                  sx={inputStyles}
                  disableUnderline={true}
                  placeholder={localization.translate.discount}
                />
              </Stack>
              <Stack spacing={2}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.main_category}
                </InputLabel>
                <Select
                  sx={inputStyles}
                  name="category_id"
                  variant="standard"
                  disableUnderline={true}
                  value={mainCategory}
                  onChange={handelMainCategory}
                  required>
                  {categoriesData.map((category, index) => {
                    return (
                      <MenuItem key={index} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
              <Stack spacing={2}>
                <InputLabel sx={inputLabelStyles}>
                  {localization.translate.sub_category}
                </InputLabel>
                <Select
                  sx={inputStyles}
                  variant="standard"
                  name="sub_category_id"
                  disableUnderline={true}
                  value={subCategory}
                  onChange={handelSubCategory}
                >
                  {subCategoriesData.map((subCategory, index) => {
                    return (
                      <MenuItem key={index} value={subCategory.id}>
                        {subCategory.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
              <Stack spacing={2}>
                <InputLabel sx={inputLabelStyles}>
                  {localization.translate.description}
                </InputLabel>
                <TextareaAutosize
                  aria-label="minimum height"
                  name="description"
                  onChange={handleInputChagnge}
                  minRows={6}
                  value={productData.description}
                  placeholder={localization.translate.description}
                  style={textAreaStyles}
                />
              </Stack>
              <Stack spacing={2}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.trade_mark}
                </InputLabel>
                <Autocomplete
                  options={brands}
                  getOptionLabel={(brand) => brand.name}
                  id="auto-highlight"
                  required={true}
                  onChange={handleInputChagnge}
                  name="brand_id"
                  defaultValue={productData.brand_id}
                  autoHighlight
                  loadingText={localization.translate.loading}
                  noOptionsText={localization.translate.no_result}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={inputStyles}
                      placeholder={localization.translate.trade_mark_placeholder}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: 'var(--clr-light-gray)' }}
            />
            <Stack spacing={4} width="100%">
              <Typography
                gutterBottom
                variant="h2"
                sx={{ fontSize: '1.375rem', fontWeight: '400', alignSelf: 'flex-start' }}>
                {localization.translate.add_product_image}
              </Typography>
              <Button
                component="label"
                width="100%"
                height="200px"
                type="file"
                sx={{
                  p: 4,
                  color: 'var(--clr-primary)',
                  backgroundColor: '#F4F4F9',
                  border: '1px dashed var(--clr-primary)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1rem',
                }}>
                {file ? (
                  <Image width="400px" src={URL.createObjectURL(file)} showLoading />
                ) : (
                  <>
                    <Img src={uploadIcon} alt="cloud with up arrow" />
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      gap={0.5}>
                      <Typography
                        gutterBottom
                        variant="p"
                        sx={{
                          fontSize: '1rem',
                          fontWeight: '700',
                          color: 'var(--clr-black)',
                        }}>
                        {localization.translate.upload_image}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="p"
                        sx={{
                          fontSize: '1rem',
                          fontWeight: '700',
                          color: 'var(--clr-primary)',
                          textDecoration: 'underline',
                        }}>
                        {localization.translate.device_search}
                      </Typography>
                    </Stack>
                    <Typography
                      gutterBottom
                      variant="p"
                      sx={{ fontSize: '0.7rem', fontWeight: '400', color: '#676767' }}>
                      {localization.translate.allowed_extensions}
                    </Typography>
                  </>
                )}
                <input hidden accept="image/*" onChange={handleFileChange} type="file" />
              </Button>
            </Stack>
          </Box>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
              gap: '1rem',
            }}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ px: '3.375rem', py: '0.5rem', borderRadius: '5rem', fontWeight: '700' }}
              >
              {localization.translate.save_product}
            </LoadingButton>
            <Button
              variant="outlined"
              component={Link}
              to="/products"
              sx={{
                color: '#B81010',
                borderColor: '#B81010',
                px: '3.375rem',
                py: '0.5rem',
                borderRadius: '5rem',
                fontWeight: '700',
              }}>
              {localization.translate.cancel}
            </Button>
          </Stack>
        </Form>
      </Container>
      {alertMsg}
    </React.Fragment>
  );
};

export default AddProduct;
