import {
  doc,
  getFirestore,
  QueryDocumentSnapshot,
  QuerySnapshot,
  updateDoc,
  where,
  addDoc,
  getDoc,
  deleteDoc,
  DocumentData,
  Query,
  limitToLast,
} from 'firebase/firestore';
import {
  collection,
  query,
  orderBy,
  startAfter,
  endBefore,
  limit,
  getDocs,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';

import firebaseApp from '../configs/configs';

const databaseFirestore = getFirestore(firebaseApp);

/**
 *  Table schema, that completely defines the structure of product table
 */
class ProductTable {
  id: string;
  name: string;
  imagePath: string;
  price: string;
  status: boolean;
  catogoryId: string;
  subCatogoryId: string;
  description: string;
  brandID: string;
  isSpecial: boolean;
  measruingUnit: number;
  taste: string[];
  storeId: string;
  color: string;
  searchTags: string[];
  createDate: Date;

  fromSnapshotDoc(doc: QueryDocumentSnapshot) {
    this.id = doc.id;
    this.name = doc.data()['name'];
    this.imagePath = doc.data()['imagePath'];
    this.price = doc.data()['price'];
    this.status = parseInt(doc.data()['status']) === 0 ? true : false;
    this.catogoryId = doc.data()['catogoryId'];
    this.subCatogoryId = doc.data()['subCatogoryId'];
    this.description = doc.data()['description'];
    this.brandID = doc.data()['brandID'];
    this.isSpecial = doc.data()['isSpecial'];
    this.measruingUnit = doc.data()['measruingUnit'];
    this.taste = doc.data()['taste'];
    this.storeId = doc.data()['storeId'];
    this.color = doc.data()['color'];
    this.searchTags = doc.data()['search_tags'];
    this.createDate = doc.data()['createDate'];
  }
}

const ProductData = {
  currentProduct: typeof QuerySnapshot,
  currentSearchProduct: typeof QuerySnapshot,
  firstProduct: typeof QuerySnapshot,
  async uploadImage(imageFile: File) {
    return new Promise((resolve, reject) => {
      try {
        const imageName = `${Date.now()}-${imageFile.name}`;
        let imagePath = `products/${imageName}`;
        const storage = getStorage();
        const storageRef = ref(storage, imagePath);

        const uploadTask = uploadBytesResumable(storageRef, imageFile);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            // switch (snapshot.state) {
            //   case 'paused':
            //     console.log('Upload is paused');
            //     break;
            //   case 'running':
            //     console.log('Upload is running');
            //     break;
            // }
          },
          (error) => {
            console.log(error);
            reject('error_in_upload');
          },
          async () => {
            resolve(imageName);
          },
        );
      } catch (error) {
        console.log(error);
        reject('error_in_upload');
      }
    });
  },

  async addNew(product: ProductTable) {
    try {
      /**
       * Please set these fields to null if they have no values, otherwise the mobile app may break.
       */
      let _status = 0; 
      if (product.status){
        _status = product.status ? 0 : 1
      }
      let _taste: any=null;
      if (product.taste){
        if(product.taste.length > 1){
          _taste = product.taste;
        }
      }
      /**
       * Please don't allow these fields to be null
       * there are document in firebase nahla this one is default one.
       */
      let _brandId = "nahla";
      if (product.brandID){
        _brandId = product.brandID;
      }
      const db = getFirestore(firebaseApp);
      const createdAt = new Date();
      const productRef = collection(db, 'products');
      const _addProduct = await addDoc(productRef, {
        name: product.name,
        imagePath: product.imagePath,
        price: product.price,
        status: _status,
        catogoryId: product.catogoryId,
        subCatogoryId: product.subCatogoryId,
        description: product.description,
        brandID: _brandId,
        isSpecial: product.isSpecial,
        measruingUnit: product.measruingUnit,
        taste: _taste,
        storeId: product.storeId,
        color: null,
        createDate: createdAt,
        search_tags: product.searchTags,
        set: null
      });

      if (_addProduct.id === null) {
        console.log('adding product faild');
        return Promise.reject(false);
      }
      console.log(_addProduct);
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case 'permission-denied':
          return Promise.reject({ error: 1, message: 'permission_denied' });
        default:
          return Promise.reject({ error: 11, message: 'unknown_error' });
      }
    }
    //Step 1: Get storeId
    //Step 2: Check for required fields
    //Step 3: Set product availability
  },

  async getProductbyId(productId: string) {
    let db = getFirestore(firebaseApp);

    ///////////////////////
    // Step 1: Get product
    const docRef = doc(db, 'products', productId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return Promise.reject({ error: 115, message: 'not_item' });
    }
    let _pTable: ProductTable = new ProductTable();
    _pTable.fromSnapshotDoc(docSnap);
    return Promise.resolve(_pTable);
  },

  async getBrandbyId(brandID: string) {
    let db = getFirestore(firebaseApp);
    const docRef = doc(db, 'brand', brandID);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return Promise.reject({ error: 115, message: 'not_item' });
    }
    return Promise.resolve(docSnap.data());
  },

  async getCategorybyId(categoryId: string) {
    let db = getFirestore(firebaseApp);
    const docRef = doc(db, 'categories', categoryId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return Promise.reject({ error: 115, message: 'not_item' });
    }
    return Promise.resolve(docSnap.data());
  },

  async getSubCategorybyId(subCatogoryId: string) {
    let db = getFirestore(firebaseApp);
    const docRef = doc(db, 'subCategory', subCatogoryId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return Promise.reject({ error: 115, message: 'not_item' });
    }
    return Promise.resolve(docSnap.data());
  },

  async updateAvailable(productId: string, isAvailable: boolean) {
    try {
      const productRef = doc(databaseFirestore, 'products', productId);
      await updateDoc(productRef, {
        status: isAvailable ? 0 : 1,
      });
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.reject({ error: 52, message: 'update_failed' });
    }
  },

  async updateProduct(product: ProductTable, productId: string) {
    try {
      /**
       * Please set these fields to null if they have no values, otherwise the mobile app may break.
       */
       let _taste: any=null;
       if (product.taste){
         if(product.taste.length > 1){
           _taste = product.taste;
         }
       }
       /**
        * Please don't allow these fields to be null
        * there are document in firebase nahla this one is default one.
        */
       let _brandId = "nahla";
       if (product.brandID){
         _brandId = product.brandID;
       }
      const db = getFirestore(firebaseApp);
      const productRef = doc(db, 'products', productId);
      await updateDoc(productRef, {
        name: product.name,
        imagePath: product.imagePath,
        price: product.price,
        catogoryId: product.catogoryId,
        subCatogoryId: product.subCatogoryId,
        description: product.description,
        brandID: _brandId,
        isSpecial: product.isSpecial,
        measruingUnit: product.measruingUnit,
        taste: _taste,
        storeId: product.storeId,
        color: product.color,
        search_tags: product.searchTags,
      });
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case 'permission-denied':
          return Promise.reject({ error: 1, message: 'permission_denied' });
        default:
          return Promise.reject({ error: 11, message: 'unknown_error' });
      }
    }
  },
  async deleteProduct(productId: string, imagePath: string) {
    try {
      const storage = getStorage();
      const storageRef = ref(storage, `products/${imagePath}`);
      await deleteObject(storageRef);
      await deleteDoc(doc(databaseFirestore, 'products', productId));
      return Promise.resolve(true);
      /* This is a catch block. It is used to catch the error. */
    } catch (error) {
      switch (error.code) {
        case 'permission-denied':
          return Promise.reject({ error: 11, message: 'permission_denied' });
        default:
          return Promise.reject({ error: 11, message: 'unknown_error' });
      }
    }
  },

  async deleteImage(imagePath: string) {
    try {
      const storage = getStorage();
      const storageRef = ref(storage, `products/${imagePath}`);
      await deleteObject(storageRef);
      return Promise.resolve(true);
      /* This is a catch block. It is used to catch the error. */
    } catch (error) {
      switch (error.code) {
        case 'permission-denied':
          return Promise.reject({ error: 11, message: 'permission_denied' });
        default:
          return Promise.reject({ error: 11, message: 'unknown_error' });
      }
    }
  },
  async getFirst(productName: string) {
    // Query the first page of docs
    let first = query(collection(databaseFirestore, 'products'), orderBy('createDate'), limit(25));
    if (productName != null) {
      if (productName.length > 0) {
        first = query(
          collection(databaseFirestore, 'products'),
          orderBy('createDate'),
          where('search_tags', 'array-contains', productName), //later we will use search tags
          limit(25),
        );
      }
    }
    let documentSnapshots = await getDocs(first);
    if (documentSnapshots.docs.length <= 0) {
      first = query(
        collection(databaseFirestore, 'products'),
        where('name', '==', productName), //later we will use search tags
        limit(25),
      );
      documentSnapshots = await getDocs(first);
    }
    if (documentSnapshots.docs.length <= 0) {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
    // Get the last visible document
    this.currentProduct = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    this.firstProduct = documentSnapshots.docs[0];
    // console.log(documentSnapshots.docs)
    var productsList: Array<ProductTable> = [];
    for (var index = 0; index < documentSnapshots.docs.length; index++) {
      var _pTable: ProductTable = new ProductTable();
      _pTable.fromSnapshotDoc(documentSnapshots.docs[index]);
      productsList.push(_pTable);
    }
    return productsList;
  },

  async getNext(productName: string, mainCategory: string, subCategory: string) {
    // Query the first page of docs
    let first = query(
      collection(databaseFirestore, 'products'),
      orderBy('createDate'),
      startAfter(this.currentProduct),
      limit(25),
    );
    if (productName != null) {
      if (productName.length > 0) {
        first = query(
          collection(databaseFirestore, 'products'),
          where('search_tags', 'array-contains', productName), //later we will use search tags
          orderBy('createDate'),
          startAfter(this.currentProduct),
          limit(25),
        );
      }
    }

    if (mainCategory != null) {
      if (subCategory != null) {
        var pTable: ProductTable = new ProductTable();
        pTable.fromSnapshotDoc(this.currentProduct);
        first = query(
          collection(databaseFirestore, 'products'),
          where('subCatogoryId', '==', subCategory), //later we will use search tags
          limit(25),
          orderBy('createDate'),
          startAfter(this.currentProduct),
        );
      } else {
        first = query(
          collection(databaseFirestore, 'products'),
          where('catogoryId', '==', mainCategory), //later we will use search tags
          limit(25),
          orderBy('createDate'),
          startAfter(this.currentProduct),
        );
      }
    }
    const documentSnapshots = await getDocs(first);
    if (documentSnapshots.docs.length <= 0) {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
    // Get the last visible document
    this.currentProduct = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    this.firstProduct = documentSnapshots.docs[0];
    // console.log(documentSnapshots.docs)
    var productsList: Array<ProductTable> = [];
    for (var index = 0; index < documentSnapshots.docs.length; index++) {
      var _pTable: ProductTable = new ProductTable();
      _pTable.fromSnapshotDoc(documentSnapshots.docs[index]);
      productsList.push(_pTable);
    }
    return productsList;
  },

  async getPrevious(productName: string, mainCategory: string, subCategory: string) {
    // Query the first page of docs
    let first = query(
      collection(databaseFirestore, 'products'),
      orderBy('createDate'),
      endBefore(this.firstProduct),
      limitToLast(25),
    );
    if (productName != null) {
      if (productName.length > 0) {
        first = query(
          collection(databaseFirestore, 'products'),
          where('search_tags', 'array-contains', productName), //later we will use search tags
          orderBy('createDate'),
          endBefore(this.firstProduct),
          limitToLast(25),
        );
      }
    }

    if (mainCategory != null) {
      if (subCategory != null) {
        first = query(
          collection(databaseFirestore, 'products'),
          where('subCatogoryId', '==', subCategory), //later we will use search tags
          orderBy('createDate'),
          endBefore(this.firstProduct),
          limitToLast(25),
        );
      } else {
        first = query(
          collection(databaseFirestore, 'products'),
          where('catogoryId', '==', mainCategory), //later we will use search tags
          orderBy('createDate'),
          endBefore(this.firstProduct),
          limitToLast(25),
        );
      }
    }

    const documentSnapshots = await getDocs(first);
    if (documentSnapshots.docs.length <= 0) {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
    // Get the last visible document
    this.currentProduct = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    this.firstProduct = documentSnapshots.docs[0];
    // console.log(documentSnapshots.docs)
    var productsList: Array<ProductTable> = [];
    for (var index = 0; index < documentSnapshots.docs.length; index++) {
      var _pTable: ProductTable = new ProductTable();
      _pTable.fromSnapshotDoc(documentSnapshots.docs[index]);
      productsList.push(_pTable);
    }
    return productsList;
  },

  async getProductByCategory(categoryId: string) {
    let first: Query<DocumentData>;
    if (categoryId != null) {
      if (categoryId.length > 0) {
        first = query(
          collection(databaseFirestore, 'products'),
          where('catogoryId', '==', categoryId), //later we will use search tags
          limit(25),
          orderBy('createDate'),
        );
        const documentSnapshots = await getDocs(first);
        if (documentSnapshots.docs.length <= 0) {
          return Promise.reject({ error: 10, message: 'no_results' });
        }
        // Get the last visible document
        this.currentProduct = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        this.firstProduct = documentSnapshots.docs[0];
        // console.log(documentSnapshots.docs)
        var productsList: Array<ProductTable> = [];
        for (var index = 0; index < documentSnapshots.docs.length; index++) {
          var _pTable: ProductTable = new ProductTable();
          _pTable.fromSnapshotDoc(documentSnapshots.docs[index]);
          productsList.push(_pTable);
        }
        return productsList;
      }
    } else {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
  },

  async getProductBySubCategory(categoryId: string) {
    let first: Query<DocumentData>;
    if (categoryId != null) {
      if (categoryId.length > 0) {
        first = query(
          collection(databaseFirestore, 'products'),
          where('subCatogoryId', '==', categoryId), //later we will use search tags
          limit(25),
          orderBy('createDate'),
        );
        const documentSnapshots = await getDocs(first);
        if (documentSnapshots.docs.length <= 0) {
          return Promise.reject({ error: 10, message: 'no_results' });
        }
        // Get the last visible document
        this.currentProduct = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        this.firstProduct = documentSnapshots.docs[0];
        // console.log(documentSnapshots.docs)
        var productsList: Array<ProductTable> = [];
        for (var index = 0; index < documentSnapshots.docs.length; index++) {
          var _pTable: ProductTable = new ProductTable();
          _pTable.fromSnapshotDoc(documentSnapshots.docs[index]);
          productsList.push(_pTable);
        }
        return productsList;
      }
    } else {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
  },
};

export default ProductData;
