import { useEffect, useState } from 'react';
import localization from '../../l18n/l18n';
import { Container, Typography, Chip, Menu, Button, MenuItem, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import OrderProductCard from '../components/OrderProductCard';
import { useParams } from 'react-router-dom';
import OrderData from '../../models/orders.tsx';
import ProductData from '../../models/products.tsx';
import Bucket from '../../configs/bucket';
import Loading from '../components/loading';
import Map from '../components/map/Map';

const options = {
  shouldForwardProp: (prop) => prop !== 'bgcolor',
};

const ColoredChip = styled(
  Chip,
  options,
)(({ bgcolor }) => ({
  color: 'var(--clr-black)',
  backgroundColor: bgcolor,
  fontFamily: 'var(--ff-cairo)',
  fontSize: '0.875rem',
  fontWeight: '700',
}));

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

const Order = () => {
  const { id } = useParams();
  const initialOrderStatus = localization.translate.order_status.find(
    (status) => status.value === '0',
  );
  const [orderStatus, updateOrderStatus] = useState(initialOrderStatus);
  const [anchorEl, setAnchorEl] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [order, setOrder] = useState(null);
  const [orderDate, setOrderDate] = useState('');
  const [orderTime, setOrderTime] = useState('');
  const [products, setProducts] = useState([]);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [isPageLoading, setIsPageLoading] = useState(true);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    OrderData.getOrderById(id)
      .then((order) => {
        OrderData.getCustomerById(order.customerId).then((customer) => {
          const [date, timeValues] = order.dateTime.split(' ');
          const [hours, minutes] = timeValues.split(':');
          const time = tConvert(`${hours}:${minutes}`);
          setOrderDate(date);
          setOrderTime(time);
          setCustomer(customer);
          const [lat, lng] = order.location.split(',');
          setLatitude(lat);
          setLongitude(lng);
          setOrder(order);
          updateOrderStatus(
            localization.translate.order_status.find((status) => status.value === order.status, 10),
          );
          setIsPageLoading(false);
        });
      })
      .catch((error) => {
        console.error('error', error);
      });
  }, [id]);

  useEffect(() => {
    if (order) {
      order.producrLineItem.forEach((item) => {
        ProductData.getProductbyId(item.productId)
          .then((product) => {
            return setProducts([...products, product]);
          })
          .catch((error) => {
            return console.log('error_product', error);
          });
      });
    }
  }, [order, products]);

  const handleUpdateStatus = (event) => {
    const { value } = event.currentTarget.dataset;
    const tmp = localization.translate.order_status.find((status) => status.value === value);
    updateOrderStatus(tmp);
    OrderData.updateOrderStatus(id, value).catch((error) => {
      console.log('update_statu_error', error);
    });
    setAnchorEl(null);
  };

  if (isPageLoading) return <Loading />;

  return (
    <Container
      maxWidth="false"
      sx={{
        py: '4rem',
        px: '4rem !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '2rem',
        marginBottom: '4rem',
      }}>
      {!isPageLoading && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Typography gutterBottom variant="h1" sx={{ fontSize: '1.375rem', fontWeight: '700' }}>
              {localization.translate.order_page_title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                alignSelf: 'flex-end',
              }}>
              <ColoredChip label={orderStatus.label} bgcolor={orderStatus.color} />
              <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{ color: 'var(--clr-black)', textDecoration: 'underline' }}
                onClick={handleClick}>
                {localization.translate.change_order_status}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                {localization &&
                  localization.translate.order_status.map((status, index) => {
                    return (
                      <MenuItem data-value={status.value} onClick={handleUpdateStatus} key={index}>
                        <ColoredChip label={status.label} bgcolor={status.color} />
                      </MenuItem>
                    );
                  })}
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'flex-start',
              justifyContent: 'center',
              width: '100%',
              my: '1rem',
            }}>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Typography
                gutterBottom
                variant="h2"
                sx={{ fontSize: '1.375rem', fontWeight: '700' }}>
                {customer && customer.firstName} {customer && customer.lastName}
              </Typography>
              <Typography gutterBottom variant="p" sx={{ fontSize: '1.141rem', fontWeight: '400' }}>
                {orderDate}
              </Typography>
              <Typography gutterBottom variant="p" sx={{ fontSize: '1.141rem', fontWeight: '400' }}>
                {orderTime}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Typography gutterBottom variant="p" sx={{ fontSize: '1.375rem', fontWeight: '400' }}>
                {localization.translate.phone}:
              </Typography>
              <Typography
                gutterBottom
                variant="p"
                sx={{ fontSize: '1.375rem', fontWeight: '400', direction: 'initial' }}>
                {customer && customer.phoneNumber}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Typography gutterBottom variant="p" sx={{ fontSize: '1.375rem', fontWeight: '400' }}>
                {localization.translate.address}:
              </Typography>
              <Typography gutterBottom variant="p" sx={{ fontSize: '1.375rem', fontWeight: '400' }}>
                {order.address}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '1rem', width: '100%' }}>
              {<Map latitude={parseFloat(latitude)} longitude={parseFloat(longitude)} />}
            </Box>
            <Typography gutterBottom variant="h1" sx={{ fontSize: '1.375rem', fontWeight: '700' }}>
              {localization.translate.products}
            </Typography>
            <Grid
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat(1,1fr)',
                  md: 'repeat(3,1fr)',
                  lg: 'repeat(5,1fr)',
                },
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '2rem',
                width: '100%',
              }}
              container>
              {order.producrLineItem.map((item, index) => {
                return (
                  <OrderProductCard
                    key={index}
                    productImage={Bucket.getProductsImage(item.imagePath)}
                    productName={item.name}
                    productWeight={item.item_weight}
                    productQuantity={item.quantity}
                    productTotalPrice={item.price.toLocaleString('en-US')}
                    currency="IQD"
                    flavor={item?.flavor}
                  />
                );
              })}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '100%',
                  marginTop: '2rem',
                  maxWidth: 554,
                }}>
                <Typography
                  gutterBottom
                  variant="h1"
                  sx={{ fontSize: '1.375rem', fontWeight: '700', marginBottom: '2rem' }}>
                  {localization.translate.total}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <Typography
                    gutterBottom
                    variant="h1"
                    sx={{ fontSize: '1rem', fontWeight: '400' }}>
                    {localization.translate.order_total}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h1"
                    sx={{ fontSize: '1rem', fontWeight: '400' }}>
                    {order.totalAmount.toLocaleString('en-US')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <Typography
                    gutterBottom
                    variant="h1"
                    sx={{ fontSize: '1rem', fontWeight: '400' }}>
                    {localization.translate.shiping_price}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h1"
                    sx={{ fontSize: '1rem', fontWeight: '400' }}>
                    {order.delivery_cost.toLocaleString('en-US')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <Typography
                    gutterBottom
                    variant="h1"
                    sx={{ fontSize: '1rem', fontWeight: '400' }}>
                    {localization.translate.total_with_shipping}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h1"
                    sx={{ fontSize: '1rem', fontWeight: '400' }}>
                    {(order.totalAmount + order.delivery_cost).toLocaleString('en-US')}
                  </Typography>
                </Box>
              </Box>
              {order.notes && (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%',
                    marginTop: '2rem',
                    maxWidth: 561,
                  }}>
                  <Typography
                    gutterBottom
                    variant="h1"
                    sx={{ fontSize: '1.375rem', fontWeight: '700', marginBottom: '2rem' }}>
                    {localization.translate.note}
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'var(--clr-light-gray)',
                      py: '2rem',
                      px: '1rem',
                      borderRadius: '1rem',
                    }}>
                    <Typography
                      gutterBottom
                      variant="h1"
                      sx={{ fontSize: '1rem', fontWeight: '400', lineHeight: '27.5px' }}>
                      {order.notes}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Order;
