//your home page
import React, { useState, useEffect } from 'react';
import { Container } from '@mui/system';
import localization from '../../l18n/l18n';
import OrderCard from '../components/OrderCard';
import { Grid, Button, Typography } from '@mui/material';
import OrderData from '../../models/orders.tsx';
import { Link } from 'react-router-dom';
import Loading from '../components/loading';

const Home = () => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const goFirst = () => {
    setOrderList([]);
    OrderData.getOrderByStatus('0')
      .then((data) => {
        setOrderList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    goFirst();
  }, []);

  if (isLoading) return <Loading />;

  return (
    <Container
      maxWidth='false'
      sx={{
        py: '2rem',
        px: '4rem !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '2rem',
      }}>
      <Typography gutterBottom variant='h1' sx={{ fontSize: '1.375rem', fontWeight: '700', alignSelf: 'flex-start' }}>
        {localization.translate.home_orders_title}
      </Typography>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(1,1fr)', md: 'repeat(2,1fr)', lg: 'repeat(4,1fr)' },
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '2rem',
          width: '100%',
        }}
        container
        columns={{ xs: 1, sm: 3, md: 5 }}>
        {orderList.map((order, index) => {
          return (
            <Grid key={index} item xl={5}>
              <OrderCard order={order} currency='IQD' orderId={index} isDelivered={true} />
            </Grid>
          );
        })}
      </Grid>
      <Button variant='text' component={Link} to='/orders' sx={{ color: 'var(--clr-black)', textDecoration: 'underline', alignSelf: 'flex-end' }}>
        {localization.translate.show_more}
      </Button>
    </Container>
  );
};

export default Home;
