import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: '#4B4CB6',
    },
    secondary: {
      main: '#5574F8',
    },
    success: {
      main: '#5574F8',
    },
  },
  typography: {
    fontFamily: ['var(--ff--cairo)'],
    fontSize: 16,
    h3: {
      fontWeight: 700,
      fontSize: '2.2rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.75rem',
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
});

export default theme;
