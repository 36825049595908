import {APIEndpoints} from '../../../configs/configs.js';
import authUser from '../../../models/auth.js';
import CategoriesData from '../../../models/categories';

class ProductModels {
    async createProduct(newProductData: FormData): Promise<any> {
        try {
          const createdProduct = await this.sendCreateRequest(newProductData);
    
          if (createdProduct && createdProduct.error && createdProduct.message) {
            throw new Error(createdProduct.message);
          }
    
          return createdProduct; // Assuming the API returns the created product data
        } catch (error) {
          throw error;
        }
    }
    async fetchProducts(page: number, searchBy: string, searchValue: string): Promise<any> {
      try {
        const data = await this.fetchData(page, searchBy, searchValue);
  
        if (data && data.error && data.message) {
          throw new Error(data.message);
        }
  
        return data.results;
      } catch (error) {
        throw error;
      }
    }
    async fetchProductById(productId: string): Promise<any> {
        try {
          const data = await this.fetchDataById(productId);
    
          if (data && data.error && data.message) {
            throw new Error(data.message);
          }
          /**
           * Get Category by ID
           */ 
          try {
            let catData = await CategoriesData.getCategorieById(data.results.category_id);
            data.results.category = catData.name;
          }catch(error){
            console.log(error);
            console.log(data.results.category_id)
          }
          /**
           * Get Sub Category by ID
           */
          try {
            let subCatData = await CategoriesData.getSubCategorybyId(data.results.sub_category_id);
            data.results.category = subCatData?.name;
          }catch(error){
            console.log(error);
            console.log(data.results.category_id)
          }
          return data.results; // Assuming the API returns the product directly
        } catch (error) {
          throw error;
        }
    }    
    async updateProduct(productId: string, updatedData: any): Promise<any> {
        try {
          const updatedProduct = await this.sendUpdateRequest(productId, updatedData);
    
          if (updatedProduct && updatedProduct.error && updatedProduct.message) {
            throw new Error(updatedProduct.message);
          }
    
          return updatedProduct; // Assuming the API returns the updated product data
        } catch (error) {
          throw error;
        }
    }
    async updateAvailable(productId: string, isAvailable: boolean): Promise<any> {
      try {
        const updatedProduct = await this.sendUpdateAvailableRequest(productId, isAvailable);
  
        if (updatedProduct && updatedProduct.error && updatedProduct.message) {
          throw new Error(updatedProduct.message);
        }
  
        return updatedProduct; // Assuming the API returns the updated product data
      } catch (error) {
        throw error;
      }
    }
    async replaceMainImage(productId: string, newImageFile: File): Promise<any> {
      try {
        const updatedProduct = await this.sendReplaceImageRequest(productId, newImageFile);
  
        if (updatedProduct && updatedProduct.error && updatedProduct.message) {
          throw new Error(updatedProduct.message);
        }
  
        return updatedProduct; // Assuming the API returns the updated product data
      } catch (error) {
        throw error;
      }
    }
    async deleteProduct(productId: string): Promise<void> {
      try {
        await this.sendDeleteProductRequest(productId);
        // The request is successful, and the product is deleted, so no data is returned.
      } catch (error) {
        throw error;
      }
    }

    private async fetchData(page: number, searchBy: string, searchValue: string): Promise<any> {
      // Construct the API URL with searchBy and searchValue
      const apiUrl = `${APIEndpoints}/product/?page=${page}&searchBy=${searchBy}&searchValue=${searchValue}`;
      
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          "authorization": `Bearer ${authUser.token}`
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        const customError = new Error(errorData.message);
        customError.code = errorData.error;
        throw customError;
      }
  
      return response.json();
    }
    private async fetchDataById(productId: string): Promise<any> {
        // Construct the API URL to fetch a product by ID
        const apiUrl = `${APIEndpoints}/product/${productId}`;
    
        const response = await fetch(apiUrl);
    
        if (!response.ok) {
          const errorData = await response.json();
          const customError = new Error(errorData.message);
          customError.code = errorData.error;
          throw customError;
        }
    
        return response.json();
    }
    private async sendUpdateRequest(productId: string, updatedData: any): Promise<any> {
        // Construct the API URL to update a product by ID
        const apiUrl = `${APIEndpoints}/product/${productId}`;
    
        try {
          const response = await fetch(apiUrl, {
            method: 'PATCH', // or 'PATCH' depending on your API
            headers: {
              'Content-Type': 'application/json',
              "authorization": `Bearer ${authUser.token}`
            },
            body: JSON.stringify(updatedData),
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            const customError = new Error(errorData.message);
            customError.code = errorData.error;
            throw customError;
          }
    
          return response.json();
        } catch (error) {
          throw error;
        }
    }
    private async sendCreateRequest(newProductData: FormData): Promise<any> {
        // Construct the API URL for creating a new product
        const apiUrl = `${APIEndpoints}/product/`;
    
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              "authorization": `Bearer ${authUser.token}`
            },
            body: newProductData,
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            const customError = new Error(errorData.message);
            customError.code = errorData.error;
            throw customError;
          }
    
          return response.json();
        } catch (error) {
          throw error;
        }
    }
    private async sendUpdateAvailableRequest(productId: string, isAvailable: boolean): Promise<any> {
      // Construct the API URL to update the availability of a product by ID
      const apiUrl = `${APIEndpoints}/product/${productId}/available`;
  
      try {
        const response = await fetch(apiUrl, {
          method: 'PATCH', // Use 'PUT' method to update availability
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${authUser.token}`
          },
          body: JSON.stringify({ is_available: isAvailable }), // Send the updated availability status
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          const customError = new Error(errorData.message);
          customError.code = errorData.error;
          throw customError;
        }
  
        return response.json();
      } catch (error) {
        throw error;
      }
    }
    private async sendReplaceImageRequest(productId: string, newImageFile: File): Promise<any> {
      // Construct the API URL to replace the main image of a product by ID
      const apiUrl = `${APIEndpoints}/image/${productId}/replace`;
  
      try {
        const formData = new FormData();
        formData.append('image', newImageFile);
  
        const response = await fetch(apiUrl, {
          method: 'PATCH',
          headers: {
            'authorization': `Bearer ${authUser.token}`,
          },
          body: formData, // Send the FormData with the image file
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          const customError = new Error(errorData.message);
          customError.code = errorData.error;
          throw customError;
        }
  
        return response.json();
      } catch (error) {
        throw error;
      }
    }
    private async sendDeleteProductRequest(productId: string): Promise<void> {
      // Construct the API URL to delete a product by ID
      const apiUrl = `${APIEndpoints}/product/${productId}`;
  
      try {
        const response = await fetch(apiUrl, {
          method: 'DELETE',
          headers: {
            'authorization': `Bearer ${authUser.token}`,
          },
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          const customError = new Error(errorData.message);
          customError.code = errorData.error;
          throw customError;
        }
      } catch (error) {
        throw error;
      }
    }
  
}
  
  export default new ProductModels();
  