import { Container } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router-dom';
import localization from '../../l18n/l18n';
import { Stack, Typography, Button, Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import ProductData from '../../models/products.tsx';
import { Image } from 'mui-image';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/loading';
import productModels from './models/productModels.tsx';

const ProductDetails = () => {
  const { pid } = useParams();
  const [productData, setProductData] = React.useState({});
  const [brand, setBrand] = React.useState({});
  const [category, setCategory] = React.useState({});
  const [subCategory, setSubCategory] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [isLoading, updateIsLoading] = React.useState(false);
  const [isPageLoading, setIsPageLoading] = React.useState(true);

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const joinTaste = (productData) => {
    if (productData.taste) {
      return productData.taste.join(' , ');
    }
    return '';
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteProduct = async () => {
    updateIsLoading(true);
    productModels.deleteProduct(productData.product_id, productData.imagePath)
      .then((success) => {
        updateIsLoading(false);
        navigate('/products');
      })
      .catch((error) => {
        updateIsLoading(false);
        console.log('error_prodect_deletion', error);
      });
  };

  const getProduct = () => {
    productModels.fetchProductById(pid).then((product) => {
      console.log(product)
      setProductData(product);
      setLoading(!loading);
      setBrand(product.brand_id);
      ProductData.getCategorybyId(product.category_id).then((category) => {
        console.log(category)
        setCategory(category);
      });
      if (product.sub_category_id) {
        ProductData.getSubCategorybyId(product.sub_category_id).then((subCategory) => {
          setSubCategory(subCategory);
        });
      }

      // switch (product.measruingUnit) {
      //   case 1:
      //     setUnit(localization.translate.piece);
      //     break;
      //   case 2:
      //     setUnit(localization.translate.kg);
      //     break;
      //   case 3:
      //     setUnit(localization.translate.halfKg);
      //     break;
      //   case 4:
      //     setUnit(localization.translate.quarterKg);
      //     break;
      //   default:
      //     break;
      // }
      setIsPageLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setIsPageLoading(false);
    });
    // ProductData.getProductbyId(pid)
    //   .then((product) => {
    //     setProductData(product);
    //     setLoading(!loading);
    //     ProductData.getBrandbyId(product.brandID).then((brand) => {
    //       setBrand(brand);
    //     });
    //     ProductData.getCategorybyId(product.catogoryId).then((category) => {
    //       setCategory(category);
    //     });
    //     ProductData.getSubCategorybyId(product.subCatogoryId).then((subCategory) => {
    //       setSubCategory(subCategory);
    //     });

    //     switch (product.measruingUnit) {
    //       case 1:
    //         setUnit(localization.translate.piece);
    //         break;
    //       case 2:
    //         setUnit(localization.translate.kg);
    //         break;
    //       case 3:
    //         setUnit(localization.translate.halfKg);
    //         break;
    //       case 4:
    //         setUnit(localization.translate.quarterKg);
    //         break;
    //       default:
    //         break;
    //     }
    //     setIsPageLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setIsPageLoading(false);
    //   });
  };

  React.useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPageLoading) return <Loading />;

  return (
    <Container
      maxWidth="false"
      sx={{
        py: '2rem',
        px: '4rem !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '2rem',
      }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {localization.translate.delete_product_alert_title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {localization.translate.delete_product_alert_msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            onClick={handleDeleteProduct}
            sx={{
              color: '#B81010',
              borderColor: '#B81010',
              px: '3.375rem',
              py: '0.5rem',
              borderRadius: '5rem',
              fontWeight: '700',
            }}
            loading={isLoading}
            autoFocus>
            {localization.translate.yes}
          </LoadingButton>
          <Button
            variant="contained"
            sx={{ px: '3.375rem', py: '0.5rem', borderRadius: '5rem', fontWeight: '700' }}
            onClick={handleClose}>
            {localization.translate.no}
          </Button>
        </DialogActions>
      </Dialog>
      {!loading && (
        <>
          <Stack
            direction="row"
            sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '700', fontSize: '1.375rem' }}>
              {localization.translate.product_details}
            </Typography>
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                alignSelf: 'flex-start',
                gap: '1rem',
              }}>
              <Button
                type="button"
                component={Link}
                to={`/products/edit/${pid}`}
                variant="outlined"
                sx={{ px: '3.375rem', py: '0.5rem', borderRadius: '5rem', fontWeight: '700' }}>
                {localization.translate.edit_product}
              </Button>
              <Button
                onClick={handleClickOpen}
                variant="outlined"
                sx={{
                  color: '#B81010',
                  borderColor: '#B81010',
                  px: '3.375rem',
                  py: '0.5rem',
                  borderRadius: '5rem',
                  fontWeight: '700',
                }}>
                {localization.translate.remove_product}
              </Button>
            </Stack>
          </Stack>
          <Stack direction="row" sx={{ gap: '2rem', width: '90%' }}>
            <Box sx={{ minWidth: '400px', alignItems: 'center', justifyContent: 'center' }}>
              <Image src={productData.image_url} showLoading />
            </Box>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Stack
                direction="row"
                sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '1.375rem' }}>
                  {productData.name}
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Typography sx={{ fontWeight: '700', fontSize: '1.375rem' }}>
                    {localization.translate.currency}
                  </Typography>
                  <Typography sx={{ fontWeight: '700', fontSize: '1.375rem' }}>
                    {productData.price}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {localization.translate.trade_mark}:
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {brand}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {localization.translate.main_category}:
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {category.name}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {localization.translate.sub_category}:
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {subCategory.name}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {localization.translate.types}:
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {joinTaste(productData)}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {localization.translate.barcode}:
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {productData.barcode}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {localization.translate.description}:
                </Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '1.172rem' }}>
                  {productData.description}
                </Typography>
              </Stack>
              
            </Stack>
          </Stack>
        </>
      )}
      <Grid container spacing={2} sx={{ width: '100%', mt: '2rem' }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: '700', fontSize: '1.375rem' }}>
            {localization.translate.product_images}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {
            productData.product_image && productData.product_image.map((image) => {
              return (
                <Image src={image.image_url} showLoading />
              )
            })
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductDetails;
