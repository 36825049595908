import { doc, getFirestore, QuerySnapshot, updateDoc, arrayUnion } from 'firebase/firestore';

import firebaseApp from '../configs/configs';

const databaseFirestore = getFirestore(firebaseApp);

const UserData = {
  currentUser: typeof QuerySnapshot,

  async updateToken(userId: string, fcmToken: string) {
    try {
      const userRef = doc(databaseFirestore, 'user', userId);
      await updateDoc(userRef, {
        tokens: arrayUnion(fcmToken),
      });
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.reject({ error: 52, message: 'update_failed' });
    }
  },
};

export default UserData;
