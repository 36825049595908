import React, { useState, useEffect } from 'react';
import { Container } from '@mui/system';

import localization from '../../l18n/l18n';
import { Box, Input, Grid, Button, Typography, Stack } from '@mui/material';
import OrderCard from '../components/OrderCard';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OrderData from '../../models/orders.tsx';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Loading from '../components/loading';

const Orders = () => {
  const [orderStatus, updateOrderStatus] = React.useState('0');
  const [orderList, setOrderList] = useState([]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [firstOrder, setFirstOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event) => {
    updateOrderStatus(event.target.value);
    setIsLoading(true);
    goFirst(event.target.value);
  };

  const goFirst = (status) => {
    setOrderList([]);
    OrderData.getOrderByStatus(status)
      .then((data) => {
        setOrderList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const goNext = () => {
    setOrderList([]);
    setIsLoading(true);
    OrderData.getNext(orderStatus)
      .then((data) => {
        setOrderList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const goPrevious = () => {
    setOrderList([]);
    setIsLoading(true);
    OrderData.getPrevious(orderStatus)
      .then((data) => {
        setOrderList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    goFirst(orderStatus);
  }, [orderStatus]);
  useEffect(() => {
    if (!firstOrder) {
      setFirstOrder(orderList[0]);
      return;
    }

    if (orderList.some((order) => order.id === firstOrder.id)) {
      setIsFirstPage(true);
    } else {
      setIsFirstPage(false);
    }
  }, [orderList, firstOrder]);

  if (isLoading) return <Loading />;

  return (
    <Container
      maxWidth='false'
      sx={{
        py: '2rem',
        px: '4rem !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '2rem',
      }}>
      <Typography gutterBottom variant='h1' sx={{ fontSize: '1.375rem', fontWeight: '700', alignSelf: 'flex-start' }}>
        {localization.translate.orders_page_title}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          flexWrap: 'wrap',
          display: 'flex',
          alignItems: { xs: 'flex-start', sm: 'center' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
        }}>
        <Box
          sx={{
            flexGrow: 1,
            flexWrap: 'wrap',
            gap: '1rem',
            display: 'flex',
            alignItems: { xs: 'flex-start', sm: 'center' },
            justifyContent: { xs: 'center' },
            flexDirection: { xs: 'column', sm: 'row' },
            maxWidth: '65%',
          }}>
          <Input
            sx={{
              flexGrow: 1,
              backgroundColor: 'var(--clr-light-gray)',
              borderRadius: '5rem',
              py: '0.5rem',
              px: '2.375rem',
            }}
            placeholder={localization.translate.buyer_name_placeholder}
            disableUnderline={true}
            variant='standard'
          />
          <Button variant='contained' sx={{ px: '3.375rem', py: '0.5rem', borderRadius: '5rem', fontWeight: '700' }}>
            {localization.translate.search}
          </Button>
        </Box>
        {/* <Button
          sx={{
            px: '1.375rem',
            py: '0.5rem',
            borderRadius: '5rem',
            borderColor: 'var(--clr-light-blue)',
            fontWeight: '700',
            color: 'var(--clr-light-blue)',
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: 195,
            '&:hover': {
              color: 'var(--clr-primary)',
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                backgroundColor: '#35C37D',
              },
            },
          }}
          variant='outlined'
          endIcon={<CalendarTodayIcon />}>
          {localization.translate.select_date}
        </Button> */}
      </Box>
      <FormControl sx={{ m: 1, minWidth: 80, alignSelf: 'flex-start' }}>
        <Select labelId='demo-simple-select-autowidth-label' id='demo-simple-select-autowidth' value={orderStatus} onChange={handleChange} autoWidth>
          <MenuItem value='0'>{localization.translate.order_status_by_date}</MenuItem>
          <MenuItem value='1'>{localization.translate.order_status_not_delivered}</MenuItem>
          <MenuItem value='3'>{localization.translate.order_status_delivered}</MenuItem>
        </Select>
      </FormControl>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(1,1fr)', md: 'repeat(2,1fr)', lg: 'repeat(4,1fr)' },
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '2rem',
          width: '100%',
        }}
        container
        columns={{ xs: 1, sm: 3, md: 5 }}>
        {orderList.map((order, index) => {
          return (
            <Grid key={index} item xl={5}>
              <OrderCard key={index} order={order} currency='IQD' orderId={index} isDelivered={order.status === '3' ? false : true} />
            </Grid>
          );
        })}
      </Grid>
      {orderList.length > 0 && (
        <Stack direction='row' sx={{ minWidth: '50%', justifyContent: 'space-between' }}>
          <Button disabled={isFirstPage} onClick={goPrevious} startIcon={<KeyboardArrowRightIcon />}>
            {localization.translate.go_previous}
          </Button>
          <Button disabled={orderList.length < 24 ? true : false} onClick={goNext} endIcon={<KeyboardArrowLeftIcon />}>
            {localization.translate.go_next}
          </Button>
        </Stack>
      )}
    </Container>
  );
};

export default Orders;
