import {
  doc,
  getFirestore,
  QueryDocumentSnapshot,
  QuerySnapshot,
  updateDoc,
  where,
  getDoc,
  DocumentData,
  Query,
} from 'firebase/firestore';
import {
  collection,
  query,
  orderBy,
  startAfter,
  endBefore,
  limit,
  getDocs,
} from 'firebase/firestore';

import firebaseApp from '../configs/configs';

const databaseFirestore = getFirestore(firebaseApp);

/**
 *  Table schema, that completely defines the structure of product table
 */
class OrderTable {
  id: string;
  address: string;
  customerId: string;
  dateTime: string;
  delivery_cost: number;
  catogoryId: string;
  location: string;
  status: string;
  storId: string;
  totalAmount: number;
  producrLineItem: Array<Object>;

  fromSnapshotDoc(doc: QueryDocumentSnapshot) {
    this.id = doc.id;
    this.address = doc.data()['address'];
    this.customerId = doc.data()['customerId'];
    this.dateTime = doc.data()['dateTime'];
    this.delivery_cost = parseInt(doc.data()['delivery_cost']);
    this.catogoryId = doc.data()['catogoryId'];
    this.location = doc.data()['location'];
    this.status = doc.data()['status'];
    this.storId = doc.data()['storId'];
    this.totalAmount = doc.data()['totalAmount'];
    this.producrLineItem = doc.data()['producrLineItem'];
  }
}

const OrderData = {
  currentOrder: typeof QuerySnapshot,
  currentSearchProduct: typeof QuerySnapshot,
  async getOrderById(orderId: string) {
    const docRef = doc(databaseFirestore, 'order', orderId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      return Promise.reject({ error: 115, message: 'not_item' });
    }
    return Promise.resolve(docSnap.data());
  },

  async getOrderByStatus(status: string) {
    // Query the first page of docs
    let first: Query<DocumentData>;
    if (status === '3') {
      first = query(
        collection(databaseFirestore, 'order'),
        where('status', '==', status), //later we will use search tags
        limit(24),
      );
    } else if (status === '0') {
      first = query(collection(databaseFirestore, 'order'), orderBy('dateTime', 'desc'), limit(24));
    } else {
      first = query(
        collection(databaseFirestore, 'order'),
        where('status', '!=', '3'), //later we will use search tags
        limit(24),
      );
    }

    const documentSnapshots = await getDocs(first);
    if (documentSnapshots.docs.length <= 0) {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
    // Get the last visible document
    this.currentOrder = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    // console.log(documentSnapshots.docs)
    var orderList: Array<OrderTable> = [];
    for (var index = 0; index < documentSnapshots.docs.length; index++) {
      var _oTable: OrderTable = new OrderTable();
      _oTable.fromSnapshotDoc(documentSnapshots.docs[index]);
      orderList.push(_oTable);
    }
    return orderList;
  },

  async updateOrderStatus(orderId: string, status: string) {
    try {
      const orderRef = doc(databaseFirestore, 'order', orderId);
      await updateDoc(orderRef, {
        status: status,
      });
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.reject({ error: 52, message: 'update_failed' });
    }
  },

  async getCustomerById(customerId: string) {
    // Step 1: Get product
    const docRef = doc(databaseFirestore, 'user', customerId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return Promise.reject({ error: 115, message: 'not_item' });
    }
    return Promise.resolve(docSnap.data());
  },

  async getFirst(orderName: string) {
    // Query the first page of docs
    let first = query(collection(databaseFirestore, 'order'), orderBy('dateTime'), limit(24));
    if (orderName != null) {
      if (orderName.length > 0) {
        console.log(orderName);
        console.log('orderName');
        first = query(
          collection(databaseFirestore, 'order'),
          where('name', '==', orderName), //later we will use search tags
          limit(24),
        );
      }
    }

    const documentSnapshots = await getDocs(first);
    if (documentSnapshots.docs.length <= 0) {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
    // Get the last visible document
    this.currentOrder = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    // console.log(documentSnapshots.docs)
    var orderList: Array<OrderTable> = [];
    for (var index = 0; index < documentSnapshots.docs.length; index++) {
      var _oTable: OrderTable = new OrderTable();
      _oTable.fromSnapshotDoc(documentSnapshots.docs[index]);
      orderList.push(_oTable);
    }
    return orderList;
  },

  async getNext(status: string) {
    // Query the first page of docs
    let first: Query<DocumentData>;
    if (status === '3') {
      first = query(
        collection(databaseFirestore, 'order'),
        where('status', '==', status), //later we will use search tags
        limit(24),
        startAfter(this.currentOrder),
      );
    } else if (status === '0') {
      first = query(
        collection(databaseFirestore, 'order'),
        orderBy('dateTime'),
        limit(24),
        startAfter(this.currentOrder),
      );
    } else {
      first = query(
        collection(databaseFirestore, 'order'),
        where('status', '!=', '3'), //later we will use search tags
        limit(24),
        startAfter(this.currentOrder),
      );
    }
    const documentSnapshots = await getDocs(first);
    if (documentSnapshots.docs.length <= 0) {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
    // Get the last visible document
    this.currentOrder = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    // console.log(documentSnapshots.docs)
    var productsList: Array<OrderTable> = [];
    for (var index = 0; index < documentSnapshots.docs.length; index++) {
      var _oTable: OrderTable = new OrderTable();
      _oTable.fromSnapshotDoc(documentSnapshots.docs[index]);
      productsList.push(_oTable);
    }
    return productsList;
  },

  async getPrevious(status: string) {
    // Query the first page of docs
    let first: Query<DocumentData>;
    if (status === '3') {
      first = query(
        collection(databaseFirestore, 'order'),
        where('status', '==', status), //later we will use search tags
        limit(24),
        endBefore(this.currentOrder),
      );
    } else if (status === '0') {
      first = query(
        collection(databaseFirestore, 'order'),
        orderBy('dateTime'),
        limit(24),
        endBefore(this.currentOrder),
      );
    } else {
      first = query(
        collection(databaseFirestore, 'order'),
        where('status', '!=', '3'), //later we will use search tags
        limit(24),
        endBefore(this.currentOrder),
      );
    }

    const documentSnapshots = await getDocs(first);
    if (documentSnapshots.docs.length <= 0) {
      return Promise.reject({ error: 10, message: 'no_results' });
    }
    // Get the last visible document
    this.currentOrder = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    // console.log(documentSnapshots.docs)
    var productsList: Array<OrderTable> = [];
    for (var index = 0; index < documentSnapshots.docs.length; index++) {
      var _pTable: OrderTable = new OrderTable();
      _pTable.fromSnapshotDoc(documentSnapshots.docs[index]);
      productsList.push(_pTable);
    }
    return productsList;
  },
};

export default OrderData;
