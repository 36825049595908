import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import localization from '../../l18n/l18n';
import { Link, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import authUser from '../../models/auth';

import appLogo from '../../assets/images/logo.png';

const pages = [
  {
    title: localization.translate.home_nav,
    path: '/',
  },
  {
    title: localization.translate.products_nav,
    path: '/products',
  },
  {
    title: localization.translate.orders_nav,
    path: '/orders',
  },
];

const Img = styled('img')({
  maxWidth: 50,
});

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    authUser.signOut();
  };

  return (
    <AppBar sx={{ px: '3rem' }} position='static'>
      <Container maxWidth='false'>
        <Toolbar disableGutters sx={{ flexDirection: { xs: 'row-reverse', md: 'row' } }}>
          <Img src={appLogo} alt='Nahla brand logo' />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'>
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                  <Link to={page.path} onClick={handleCloseNavMenu}>
                    {page.title}
                  </Link>
                </MenuItem>
              ))}
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  sx={{ gap: '0.536rem', fontWeight: '700', fontSize: '1.25rem' }}
                  variant='contained'
                  startIcon={<LogoutIcon />}
                  onClick={handleLogout}>
                  {localization.translate.logout}
                </Button>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, p: '1rem' }}>
              {pages.map((page) => (
                <MenuItem
                  selected={location.pathname === page.path ? true : false}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'var(--clr-white)',
                      borderRadius: '2rem',
                      px: '2rem',
                      py: '0.5rem',
                      fontWeight: '700',
                      color: 'var(--clr-primary)',
                    },
                    mx: '1rem',
                    '&:hover': {
                      color: 'var(--crl-white)',
                    },
                  }}
                  key={page.title}
                  onClick={handleCloseNavMenu}>
                  <Link to={page.path} onClick={handleCloseNavMenu}>
                    {page.title}
                  </Link>
                </MenuItem>
              ))}
            </Box>
            <MenuItem onClick={handleCloseNavMenu}>
              <Button
                sx={{ color: 'var(--clr-white)', gap: '0.536rem', fontWeight: '700', fontSize: '1.25rem' }}
                variant='outlined'
                startIcon={<LogoutIcon />}
                onClick={handleLogout}>
                {localization.translate.logout}
              </Button>
            </MenuItem>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
