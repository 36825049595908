import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  mt: '2rem',
};
const Loading = () => {
  return (
    <Box sx={style}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
