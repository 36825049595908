import React from 'react';
import Card from '@mui/material/Card';
import { CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import localization from '../../l18n/l18n';
import { Image } from 'mui-image';

const OrderProductCard = (props) => {
  return (
    <Card sx={{ maxWidth: 254, boxShadow: 2 }}>
      <Image
        showLoading
        sx={{ flexGrow: 1, maxHeight: 200 }}
        src={props.productImage}
        alt="green iguana"
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          backgroundColor: 'var(--clr-light-gray)',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Typography gutterBottom variant="h3" sx={{ fontSize: '1.125rem' }}>
            {props.productName}
          </Typography>
          {props?.flavor && (
            <Typography
              gutterBottom
              variant="p"
              sx={{ fontSize: '1.125rem', color: 'var(--clr-gray)' }}>
              {'النوع: '}
              {props?.flavor}
            </Typography>
          )}
          <Typography
            gutterBottom
            variant="p"
            sx={{ fontSize: '1.125rem', color: 'var(--clr-gray)' }}>
            {props.productWeight}
          </Typography>
          <Typography
            gutterBottom
            variant="p"
            sx={{ fontSize: '1.125rem', color: 'var(--clr-gray)' }}>
            {localization.translate.quantity}: {props.productQuantity}
          </Typography>
          <Box
            sx={{
              flexWrap: 'wrap',
              display: 'flex',
              alignItems: 'center',
              gap: '0.2rem',
              justifyContent: 'flex-end',
              flexDirection: 'row-reverse',
              fontSize: '1.125rem',
              color: 'var(--clr-black)',
            }}>
            <Typography gutterBottom variant="p">
              {props.productTotalPrice}
            </Typography>
            <Typography gutterBottom variant="p">
              {props.currency}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrderProductCard;
