import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import localization from '../../l18n/l18n';
import { useNavigate } from 'react-router-dom';
import productModels from '../products/models/productModels.tsx';

const ProductCard = (props) => {
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();
  // console.log(props);
  const handleChange = async (event) => {
    setChecked(event.target.checked);
    console.log(event.target.checked);
    try {
      await productModels.updateAvailable(props.product.product_id, event.target.checked);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    setChecked(props.product.is_available);
  }, [props.product.is_available]);

  const hanldleProductDetails = () => {
    navigate(`/products/details/${props.product.product_id}`);
  };
  return (
    <Card sx={{ maxWidth: 354, boxShadow: 2, cursor: 'pointer' }}>
      <CardMedia
        sx={{ objectFit: 'contain' }}
        onClick={hanldleProductDetails}
        component="img"
        height="140"
        image={props.image}
        alt="product image"
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Typography gutterBottom variant="h3" sx={{ fontSize: '1.125rem' }}>
            {props.product.name}
          </Typography>
          <Box
            sx={{
              flexWrap: 'wrap',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}>
            <Box
              sx={{
                flexWrap: 'wrap',
                display: 'flex',
                alignItems: 'center',
                gap: '0.2rem',
                justifyContent: 'flex-start',
                flexDirection: 'row-reverse',
                fontSize: '1.125rem',
                color: 'var(--clr-black)',
              }}>
              <Typography gutterBottom variant="p">
                {props.product.price}
              </Typography>
              <Typography gutterBottom variant="p">
                {'IQD'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={checked ? localization.translate.available : localization.translate.not_available}
          labelPlacement="end"
        />
      </CardContent>
    </Card>
  );
};

export default ProductCard;
