import firebaseApp from '../configs/configs';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth';

const auth = getAuth(firebaseApp);
const authUser = {
  user: null,
  token: null,
  init() {
    onAuthStateChanged(auth, async(user) => {
      console.log(user.getIdToken());
      if (user) {
        const token = await user.getIdToken();
          // Store the user object and token
          authUser.user = user;
          authUser.token = token;
          console.log(token);
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...
      } else {
        // User is signed out
        // ...
        authUser.user = null;
        authUser.token = null;
        return null;
      }
    });
  },
  async signIn(email, password, isChecked) {
    try {
      let userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user == null) {
        return Promise.reject({ error: 11, message: 'signin_failed' });
      }
      authUser.user = userCredential;
      if (isChecked) {
        setPersistence(auth, browserLocalPersistence);
      } else {
        setPersistence(auth, browserSessionPersistence);
      }
      return Promise.resolve(authUser.user);
    } catch (error) {
      console.log(error);
      return Promise.reject({ error: 20, message: 'unknow_error' });
    }
  },
  async signOut() {
    try {
      await auth.signOut();
      authUser.user = null;
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.reject({ error: 20, message: 'unknow_error' });
    }
  },
};
export default authUser;
export { auth };
