import React, { useMemo } from 'react';
import GoogleMapReact from 'google-map-react';

import './Marker.css';

const Marker = (props) => {
  const { color, name } = props;
  return (
    <div>
      <div className='pin bounce' style={{ backgroundColor: color, cursor: 'pointer' }} title={name} />
      <div className='pulse' />
    </div>
  );
};

const Map = ({ latitude, longitude }) => {
  const getMapOptions = () => {
    return {
      disableDefaultUI: true,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
    };
  };

  const center = useMemo(() => ({ lat: parseFloat(latitude), lng: parseFloat(longitude) }), [latitude, longitude]);
  return (
    <div style={{ height: '600px', width: '100%' }}>
      <GoogleMapReact
        options={getMapOptions}
        bootstrapURLKeys={{ key: 'AIzaSyBBwNdl1DXnTdr_weYSXJTjg9wdgE6TtFg' }}
        defaultCenter={center}
        defaultZoom={16}>
        <Marker lat={center.lat} lng={center.lng} name='My Marker' color='blue' />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
