import ar from './languages/ar';
import arError from './languages/ar_error';

const Localization = {
  currentLanguage: {
    id: 1,
    name: 'arabic',
    native_name: 'عربي',
    code: 'ar', //ISO 639-1 Language Codes see more on GETTING-STARTED.md file
    dir: 'rtl',
  },
  availableLanguage: [
    {
      id: 1,
      name: 'arabic',
      native_name: 'عربي',
      code: 'ar', //ISO 639-1 Language Codes see more on GETTING-STARTED.md file
      dir: 'rtl',
    },
  ],
  translate: Object.assign(ar, arError),
  init() {
    let local = localStorage.getItem('localization');
    switch (local) {
      default:
        Localization.translate = Object.assign(ar, arError);
        Localization.currentLanguage = this.availableLanguage[0];
        document.dir = 'rtl';
    }
  },
  setCurrentLanguage(LangCode) {
    window.location.reload();
  },
};
export default Localization;
