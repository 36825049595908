import React from 'react';
import { Container } from '@mui/system';
import {
  Stack,
  InputLabel,
  Input,
  Typography,
  Select,
  MenuItem,
  TextareaAutosize,
  styled,
  Button,
  Autocomplete,
  TextField,
  Grid,
} from '@mui/material';
import localization from '../../l18n/l18n';
import uploadIcon from '../../assets/images/upload-icon.svg';
import CategoriesData from '../../models/categories';
import BrandsData from '../../models/brands';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Image } from 'mui-image';
import productModels from './models/productModels.tsx';
import Localization from '../../l18n/l18n';

const inputStyles = {
  backgroundColor: 'var(--clr-light-gray)',
  borderRadius: '5rem',
  py: '0.5rem',
  px: '2.375rem',
  fontSize: '1rem',
  color: 'var(--clr-black)',
  fontweight: 400,
  '.MuiOutlinedInput-notchedOutline': {
    border: 'inherit',
  },
  '.MuiOutlinedInput-root': {
    position: 'initial !important',
    padding: 0,
  },
  svg: {
    mr: '1rem',
  },
  '&.Mui-error': {
    border: '1px solid red !important',
    color: 'red',
  },
};

const inputLabelStyles = {
  fontSize: '1rem',
  color: 'var(--clr-black)',
  fontweight: 400,
  span: {
    color: 'red',
  },
};

const textAreaStyles = {
  width: '100%',
  backgroundColor: 'var(--clr-light-gray)',
  borderRadius: '1rem',
  fontSize: '1rem',
  color: 'var(--clr-black)',
  fontweight: 400,
};

const Img = styled('img')({
  width: 75,
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditProduct = () => {
  const intialProductData = {
    name: '',
    imagePath: '',
    price: '',
    catogory_id: '',
    subCatogoryId: '',
    description: '',
    brand_id: '',
    isSpecial: false,
    measruingUnit: 1,
    taste: [],
    storeId: 'YrHOBuBr7ViSoQYZ6aB7',
    color: [],
    searchTags: [],
  };

  const [mainCategory, updateMainCategory] = React.useState('');
  const [categoriesData, setCategoriesData] = React.useState([]);
  const [subCategoriesData, setSubCategoriesData] = React.useState([]);
  const [subCategory, updateSubCategory] = React.useState('');
  const [file, setFile] = React.useState();
  const [productData, setProductData] = React.useState(intialProductData);
  const [brands, setBrands] = React.useState([]);
  const { pid } = useParams();
  const [alertMsg, setAlertMsg] = React.useState(<></>)

  const handelMainCategory = (event) => {
    console.log(event.target.value);
    updateMainCategory(event.target.value);
    setProductData({ ...productData, [event.target.name]: event.target.value });
  };

  const handelSubCategory = (event) => {
    updateSubCategory(event.target.value);
    setProductData({ ...productData, [event.target.name]: event.target.value });
  };

  const handelSubmit = async (event) => {
    event.preventDefault();
    productModels.updateProduct(pid, productData)
      .then((product) => {
        setAlertMsg(
          <Snackbar
            open
            onClose={() => { setAlertMsg(<></>) }}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              style={{ fontSize: '1.2rem', fontFamily: 'var(--ff-cairo)' }}
              severity="success">
              تم تعديل المنتج بنجاح
            </Alert>
          </Snackbar>
        )
      });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    productModels.replaceMainImage(pid, event.target.files[0])
    .then((data)=>{
      setAlertMsg(
        <Snackbar
          open
          onClose={() => { setAlertMsg(<></>) }}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert
            style={{ fontSize: '1.2rem', fontFamily: 'var(--ff-cairo)' }}
            severity="success">
            تم استبدال الصورة الرئيسية بنجاح
          </Alert>
        </Snackbar>
      )
    })
  };

  const handleInputChagnge = (event) => {
    setProductData({ ...productData, [event.target.name]: event.target.value });
  };
 

  const fetchData = async () => {
    CategoriesData.getAllCategories().then((categories) => {
      BrandsData.getAllBrands().then((brands) => {
        setCategoriesData(categories);
        setBrands(brands);
      });
    });
  };

  React.useEffect(() => {
    productModels.fetchProductById(pid).then((product) => {
      console.log(product)
      setProductData(product);
    })
      .catch((error) => {
        console.log(error);
      });
    fetchData();
    // eslint-disable-next-line
  }, [pid]);

  React.useEffect(() => {
    const fetchData = async () => {
      const categories = await CategoriesData.getSubCategories(mainCategory);
      setSubCategoriesData(categories);
    };
    fetchData();
    // eslint-disable-next-line
  }, [mainCategory]);

  return (
    <React.Fragment>
      <Form onSubmit={handelSubmit}>
        <Grid container spacing={2}
          sx={{
            py: '2rem',
            px: '4rem !important',
          }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="h2"
                  sx={{ fontSize: '1.375rem', fontWeight: '700', alignSelf: 'flex-start' }}>
                  {localization.translate.edit_product}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.product_name}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Input
                  fullWidth
                  onChange={handleInputChagnge}
                  name="name"
                  value={productData.name}
                  sx={inputStyles}
                  disableUnderline={true}
                  placeholder={localization.translate.product_name}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.product_price}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Input
                  fullWidth
                  type="number"
                  onChange={handleInputChagnge}
                  name="price"
                  value={productData.price}
                  sx={inputStyles}
                  disableUnderline={true}
                  placeholder={localization.translate.product_price}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.main_category}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  sx={inputStyles}
                  name="category_id"
                  variant="standard"
                  disableUnderline={true}
                  value={productData.category_id}
                  onChange={handelMainCategory}
                  required>
                  {categoriesData.map((category, index) => {
                    return (
                      <MenuItem key={index} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.sub_category}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  sx={inputStyles}
                  variant="standard"
                  name="subCatogoryId"
                  disableUnderline={true}
                  value={subCategory}
                  onChange={handelSubCategory}
                  required>
                  {subCategoriesData.map((subCategory, index) => {
                    return (
                      <MenuItem key={index} value={subCategory.id}>
                        {subCategory.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={inputLabelStyles}>
                  {localization.translate.description}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  fullWidth
                  aria-label="minimum height"
                  name="description"
                  onChange={handleInputChagnge}
                  minRows={6}
                  value={productData.description}
                  placeholder={localization.translate.description}
                  style={textAreaStyles}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel required sx={inputLabelStyles}>
                  {localization.translate.trade_mark}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={brands}
                  getOptionLabel={(brand) => brand.name}
                  id="auto-highlight"
                  required={true}
                  onChange={handleInputChagnge}
                  name="brand_id"
                  defaultValue={productData.brand_id}
                  autoHighlight
                  loadingText={localization.translate.loading}
                  noOptionsText={localization.translate.no_result}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={inputStyles}
                      placeholder={localization.translate.trade_mark_placeholder}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={inputLabelStyles}>
                  {localization.translate.barcode}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Input
                  fullWidth
                  name="barcode"
                  onChange={handleInputChagnge}
                  value={productData.barcode}
                  style={{ flex: '1 0 auto' }}
                  sx={inputStyles}
                  disableUnderline={true}
                  placeholder={localization.translate.barcode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="h2"
                  sx={{ fontSize: '1.375rem', fontWeight: '400', alignSelf: 'flex-start' }}>
                  {localization.translate.replace_product_image}
                </Typography>
                <Button
                  component="label"
                  width="100%"
                  height="200px"
                  type="file"
                  sx={{
                    p: 4,
                    color: 'var(--clr-primary)',
                    backgroundColor: '#F4F4F9',
                    border: '1px dashed var(--clr-primary)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                  }}>
                  {file ? (
                    <Image width="400px" src={URL.createObjectURL(file)} showLoading />
                  ) : (
                      <>
                        <Img src={uploadIcon} alt="cloud with up arrow" />
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          gap={0.5}>
                          <Typography
                            gutterBottom
                            variant="p"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: '700',
                              color: 'var(--clr-black)',
                            }}>
                            {localization.translate.upload_image}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="p"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: '700',
                              color: 'var(--clr-primary)',
                              textDecoration: 'underline',
                            }}>
                            {localization.translate.device_search}
                          </Typography>
                        </Stack>
                        <Typography
                          gutterBottom
                          variant="p"
                          sx={{ fontSize: '0.7rem', fontWeight: '400', color: '#676767' }}>
                          {localization.translate.allowed_extensions}
                        </Typography>
                      </>
                  )}
                  <input hidden accept="image/*" onChange={handleFileChange} type="file" />
                </Button>
              </Grid>
              <Grid item xs={12}>
                {Localization.translate.current_image}
              </Grid>
              <Grid item xs={12}>
                <Image width="400px" src={productData.image_url} showLoading />
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        <Container
          maxWidth="false"
          sx={{
            py: '2rem',
            px: '4rem !important',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-center',
            gap: '4rem',
          }}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
              gap: '1rem',
            }}>
            <LoadingButton
              type="button"
              variant="contained"
              sx={{ px: '3.375rem', py: '0.5rem', borderRadius: '5rem', fontWeight: '700' }}
              onClick={handelSubmit}
            >
              {localization.translate.save_edits}
            </LoadingButton>
            <Button
              variant="outlined"
              component={Link}
              to="/products"
              sx={{
                color: '#B81010',
                borderColor: '#B81010',
                px: '3.375rem',
                py: '0.5rem',
                borderRadius: '5rem',
                fontWeight: '700',
              }}>
              {localization.translate.cancel}
            </Button>
          </Stack>
        </Container>
      </Form>
      {alertMsg}
    </React.Fragment>
  );
};

export default EditProduct;
