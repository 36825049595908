import { useContext } from 'react';
import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './index';

const useAuth = () => {
  const { user, loading } = useContext(UserContext);
  return user && !loading;
};

const ProtectedRoutes = () => {
  const location = useLocation();
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to='/login' replace state={{ from: location }} />;
};

export default ProtectedRoutes;
