import firebaseApp from '../configs/configs';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound, setToken) => {
  return getToken(messaging, {
    vapidKey:
      'BCECTShnKomr1TKuR6npV90yZt59mbGyKWPT4OveP9vknuYgSWwKiTybkZcdKge42RSqdVqaKOA1j-QewvuWagM',
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        setToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const getFcmToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BCECTShnKomr1TKuR6npV90yZt59mbGyKWPT4OveP9vknuYgSWwKiTybkZcdKge42RSqdVqaKOA1j-QewvuWagM',
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      return null;
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
