import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

import Home from './pages/home/home';
import Login from './pages/login/login';
import NavBar from './pages/navbar/navbar';
import Products from './pages/products/products';
import Orders from './pages/orders/Orders';

import authUser, { auth } from './models/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import ProtectedRoutes from './ProtectedRoutes';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Order from './pages/orders/Order';
import AddProduct from './pages/products/addProduct';
import ProductDetails from './pages/products/productDetails';
import { onMessageListener } from './models/notification';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import EditProduct from './pages/products/editProduct';
// import UserData from './models/users.tsx';

export const UserContext = React.createContext();

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const App = () => {
  const [user, loading] = useAuthState(auth);
  const [open, setOpen] = React.useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // Initialize Firebase Authentication when the component mounts
  useEffect(() => {
    authUser.init();
  }, []);
  if (loading) return;

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload.notification.title, body: payload.notification.body });
      setOpen(true);
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <React.Fragment>
      <UserContext.Provider value={{ user, loading }}>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              {user && <NavBar />}
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                
                onClose={handleClose}>
                <Alert
                  elevation={6}
                  icon={false}
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: '100%', mt: '4rem', maxWidth: { sm: '100%', md: '300px' } }}>
                  <AlertTitle>{notification.title}</AlertTitle>
                  {notification.body}
                </Alert>
              </Snackbar>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<ProtectedRoutes />}>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/products" element={<Products />} />
                  <Route exact path="/orders" element={<Orders />} />
                  <Route exact path="/orders/:id" element={<Order />} />
                  <Route exact path="/products/new" element={<AddProduct />} />
                  <Route exact path="/products/details/:pid" element={<ProductDetails />} />
                  <Route exact path="/products/edit/:pid" element={<EditProduct />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      </UserContext.Provider>
    </React.Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
